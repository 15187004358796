<template>
  <div class="testimonials">
    <carousel
      :autoplay="true"
      :loop="true"
      :center="true"
      :responsive="{
        0: { items: 1, nav: true, stagePadding: 5, margin: 20 },
        768: { items: 2, nav: true, margin: 30 },
        1400: { items: 3, nav: true, margin: 20 }
      }"
    >
      <div class="blog-card">
        <a class="review" href="https://www.hilton.com/en/hotels/mandidi-doubletree-stoke-on-trent/" target="_blank">
          <div class="review-img-cont">
            <img src="@/img/testimonials/Hilton.jpg"/>
          </div>
          <div class="review-text-cont">
            <h4 class="review-header">Business Name</h4>
            <p class="reviews-body-text">
              Review from such and such and such talking about all of the great service and benefits they have taken working with you.
            </p>
          </div>
        </a>
      </div>

      


    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import $ from "jquery";

export default {
  components: { carousel },
  mounted() {
    $(".owl-prev").html("Previous");
    $(".owl-next").html("Next");
  }
};
</script>
