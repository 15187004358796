import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ViewportHeight from "@/components/core/containers/ViewportHeight.vue";
import Section from "@/components/core/containers/Section.vue";
import Column from "@/components/core/layout/Column.vue";
import {
  library
} from "@fortawesome/fontawesome-svg-core";
import {
  faStar
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

library.add(faStar, faLinkedin);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("Column", Column);
Vue.component("Section", Section);
Vue.component("ViewportHeight", ViewportHeight);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");