<template>
  <div
    :class="[
      'section',
      center ? 'section-center' : '',
      middle ? 'section-middle' : '',
      bottom ? 'section-bottom' : '',
      fill ? 'height100' : ''
    ]"
  >
    <!-- Larger top bottom padding than left right -->
    <div
      v-if="pad"
      :class="[
        pad ? (small ? 'pad2' : 'pt4 pb4 pl2 pr2') : '',
        ,
        fill ? 'height100' : ''
      ]"
    >
      <div v-if="wrap" :class="['wrap', fill ? 'height100' : '']">
        <slot></slot>
      </div>
      <slot v-else></slot>
    </div>
    <div v-else-if="wrap" :class="['wrap', , fill ? 'height100' : '']">
      <slot></slot>
    </div>
    <div v-else :class="[fill ? 'height100' : '']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // If true, wraps the content to the site max width
    wrap: Boolean,
    // If true, pads out the content
    pad: Boolean,
    // If small, things like padding will be reduced
    small: Boolean,
    // If true, centers the content horizontally
    center: Boolean,
    // If true, centers the content vertically
    middle: Boolean,
    // If true, aligns the content vertically to the bottom
    bottom: Boolean,
    // If true, sets the height to 100%
    fill: Boolean
  }
};
</script>

<style lang="scss" scoped>
.section-center:not(.section-middle) {
  text-align: center;
}

.section-middle {
  display: flex;
  align-items: center;

  // Must fill content height
  height: 100%;
}

.section-bottom {
  display: flex;
  align-items: flex-end;

  // Must fill content height
  height: 100%;
}

// Horizontal center if both vertical and horizontal specified
.section-middle.section-center {
  justify-content: center;
}
</style>
