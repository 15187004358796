var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'section',
    _vm.center ? 'section-center' : '',
    _vm.middle ? 'section-middle' : '',
    _vm.bottom ? 'section-bottom' : '',
    _vm.fill ? 'height100' : ''
  ]},[(_vm.pad)?_c('div',{class:[
      _vm.pad ? (_vm.small ? 'pad2' : 'pt4 pb4 pl2 pr2') : '',
      ,
      _vm.fill ? 'height100' : ''
    ]},[(_vm.wrap)?_c('div',{class:['wrap', _vm.fill ? 'height100' : '']},[_vm._t("default")],2):_vm._t("default")],2):(_vm.wrap)?_c('div',{class:['wrap', , _vm.fill ? 'height100' : '']},[_vm._t("default")],2):_c('div',{class:[_vm.fill ? 'height100' : '']},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }