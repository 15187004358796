<template>
  <div class="testimonials">
    <carousel
      :autoplay="true"
      :loop="true"
      :center="true"
      :responsive="{
        0: { items: 1, nav: true, stagePadding: 5, margin: 20 },
        320: { items: 1, nav: true, margin: 30 },
        480: { items: 3, nav: true, margin: 30 },
        700: { items: 3, nav: true, margin: 30 },
        1400: { items: 5, nav: true, margin: 20 }
      }"
    >

      <div class="blog-card">
          <a href="https://www.stairbox.com/" target="_blank">
            <img src="@/img/testimonials/StairBox.jpg" />
          </a>
      </div>
      <div class="blog-card">
          <a href="https://www.hilton.com/en/hotels/mandidi-doubletree-stoke-on-trent/" target="_blank">
            <img src="@/img/testimonials/Hilton.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.altontowers.com/" target="_blank">
            <img src="@/img/testimonials/alton-towers.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.churchill1795.com/" target="_blank">
            <img src="@/img/testimonials/Churchill.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://dougiemac.org.uk/" target="_blank">
            <img src="@/img/testimonials/DMH.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.bestwestern.co.uk/hotels/best-western-stoke-on-trent-city-centre-hotel-84268" target="_blank">
            <img src="@/img/testimonials/bestwestern.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.cazoo.co.uk/" target="_blank">
            <img src="@/img/testimonials/cazoo.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.stokesfc.ac.uk/" target="_blank">
            <img src="@/img/testimonials/stoke sixth form.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://fiveguys.co.uk/" target="_blank">
            <img src="@/img/testimonials/five guys.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.burgerking.co.uk/" target="_blank">
            <img src="@/img/testimonials/burger-king.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://mclubspaandfitness.co.uk/" target="_blank">
            <img src="@/img/testimonials/mclub.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.waterworld.co.uk/" target="_blank">
            <img src="@/img/testimonials/waterworld.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.mind.org.uk/donate/speak-to-us?gclid=Cj0KCQjwyYKUBhDJARIsAMj9lkEUnLlRE2U-wlLUkjMw3fRhamnOpBUXYhr_N0A8OYViBKBYKWQoM2waAm7_EALw_wcB" target="_blank">
            <img src="@/img/testimonials/MIND.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.staffsmoorlands.gov.uk/" target="_blank">
            <img src="@/img/testimonials/moorlands.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://thealchemist.uk.com/" target="_blank">
            <img src="@/img/testimonials/alchemist-brand.png" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.port-vale.co.uk/" target="_blank">
            <img src="@/img/testimonials/Port_Vale_logo.png" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.atgtickets.com/venues/regent-theatre/info/" target="_blank">
            <img src="@/img/testimonials/ATG.jpg" />
          </a>
      </div>

      <div class="blog-card">
          <a href="https://www.denstonecollege.org/" target="_blank">
            <img src="@/img/testimonials/denstone college.jpg" />
          </a>
      </div>


    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import $ from "jquery";

export default {
  components: { carousel },
  mounted() {
    $(".owl-prev").html("Previous");
    $(".owl-next").html("Next");
  }
};
</script>
