<template>
  <div :class="[ first ? '' : 'mt-6 border-t border-gray-200 pt-6' ]">
    <div>
      <dt class="text-lg leading-7">
        <!-- Expand/collapse question button -->
        <div
          @click="itemOpen = !itemOpen"
          class="text-center w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
        >
          <span class="font-medium text-center text-gray-900">
            <b>{{question}}</b>
          </span>
          <br>
          
          <span class="ml-6 h-7 flex items-center">
            
            <!--
                      Expand/collapse icon, toggle classes based on question open state.

                      Open: "-rotate-180", Closed: "rotate-0"
            -->
            <svg
              :class="[ itemOpen ? '-rotate-180' : 'rotate-0' ]"
              class="h-8 w-8 transform"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </div>
      </dt>
      <dd v-if="itemOpen" class="mt-2 pr-12">
        <p class="text-base leading-6 text-gray-500">
          <slot />
        </p>
      </dd>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: ["first", "question", "open", "faqimage"],
  data() {
    return {
      itemOpen: this.open
    };
  }
});
</script>

<style scoped>
</style>