<template>
  <div id="app">
    <Headline />
    <Navigation />
    <router-view />
    <Footer />
    <modal v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<style lang="scss">
@import "@/styles/style.scss";
</style>

<script>
import Headline from "@/components/Headline.vue";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import modal from "@/components/ContactModal.vue";

export default {
  name: 'app',
  metaInfo: {
    title: 'Ignite Facilities',
    titleTemplate: '%s | Ignite Facilities',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Ignite Facilities'
      },
      { 
        vmid: 'description',
        property: 'og:description',
        content: 'Ignite Facilities offers premier mechanical and plumbing services nationwide. Contact us for expert solutions tailored to your needs.'
      }
    ]
  },
  components: {
    Navigation,
    Headline,
    Footer,
    modal,
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>
