var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'col',
    {
      'desk-hidden': _vm.desktopHidden,
      'lap-hidden': _vm.laptopHidden,
      'tab-hidden': _vm.tabletHidden,
      'mob-hidden': _vm.mobileHidden,
      'tiny-hidden': _vm.tinyMobileHidden
    },
    _vm.fill ? 'fill' : '',
    _vm.columnWidth ? 'w' + _vm.columnWidth : '',
    _vm.pad ? 'pad2' : '',
    _vm.top ? 'align-top' : '',
    _vm.middle ? 'align-middle' : '',
    _vm.bottom ? 'align-bottom' : '',
    _vm.left ? 'text-left' : '',
    _vm.right ? 'text-right' : '',
    _vm.center ? 'text-center' : '',
    _vm.desktop ? 'lap-hidden tab-hidden mob-hidden' : '',
    _vm.laptop ? 'desk-hidden tab-hidden mob-hidden' : '',
    _vm.tablet ? 'desk-hidden lap-hidden mob-hidden' : '',
    _vm.mobile ? 'desk-hidden lap-hidden tab-hidden' : '',
    _vm.tinyMobile ? 'desk-hidden lap-hidden tab-hidden mob-hidden' : '',
    _vm.belowDesktopWidth ? 'below-desk-' + _vm.belowDesktopWidth : '',
    _vm.belowLaptopWidth ? 'below-lap-' + _vm.belowLaptopWidth : '',
    _vm.belowTabletWidth ? 'below-tab-' + _vm.belowTabletWidth : '',

    _vm.belowDesktopMiddle ? 'below-desk-align-middle' : '',
    _vm.belowLaptopMiddle ? 'below-lap-align-middle' : '',
    _vm.belowTabletMiddle ? 'below-tab-align-middle' : '',

    _vm.belowDesktopTop ? 'below-desk-align-top' : '',
    _vm.belowLaptopTop ? 'below-lap-align-top' : '',
    _vm.belowTabletTop ? 'below-tab-align-top' : '',

    _vm.belowDesktopBottom ? 'below-desk-align-bottom' : '',
    _vm.belowLaptopBottom ? 'below-lap-align-bottom' : '',
    _vm.belowTabletBottom ? 'below-tab-align-bottom' : '',

    _vm.belowDesktopTextCenter ? 'below-desk-text-center' : '',
    _vm.belowLaptopTextCenter ? 'below-lap-text-center' : '',
    _vm.belowTabletTextCenter ? 'below-tab-text-center' : '',
    _vm.belowDesktopTextLeft ? 'below-desk-text-left' : '',
    _vm.belowLaptopTextLeft ? 'below-lap-text-left' : '',
    _vm.belowTabletTextLeft ? 'below-tab-text-left' : '',
    _vm.belowDesktopTextRight ? 'below-desk-text-right' : '',
    _vm.belowLaptopTextRight ? 'below-lap-text-right' : '',
    _vm.belowTabletTextRight ? 'below-tab-text-right' : '',
    _vm.desktopWidth ? 'desk-' + _vm.desktopWidth : '',
    _vm.laptopWidth ? 'lap-' + _vm.laptopWidth : '',
    _vm.tabletWidth ? 'tab-' + _vm.tabletWidth : '',
    _vm.mobileWidth ? 'mob-' + _vm.mobileWidth : '',
    _vm.tinyMobileWidth ? 'tiny-' + _vm.tonyMobileWidth : ''
  ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }